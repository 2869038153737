import i18next from 'i18next';
import ar from './navigation-i18n/ar';
import en from './navigation-i18n/en';
import tr from './navigation-i18n/tr';

i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('tr', 'navigation', tr);
i18next.addResourceBundle('ar', 'navigation', ar);

const navigationConfig = [
	{
		id: "document-add-Drop",
		title: "Drop And Upload",
		type: "item",
		url: "/apps/dropAndUpload/new",
		exact: true,
		auth: ["Admin", "Customer", "Franchisee"],
	},
	{
		id: "document-cart-list",
		title: "Cart List",
		type: "item",
		url: "/apps/cartlist",
		exact: true,
		// auth: ['Admin'],
	},
	{
		id: "users-list",
		title: "Users",
		type: "item",
		url: "/apps/users",
		exact: true,
		auth: ['Admin'],
	},
	{
		id: "user-profie",
		title: "Profile",
		type: "item",
		url: "/apps/profile",
		exact: true,
		auth: ['Franchisee'],
	},
	{
		id: "settings",
		title: "Printers",
		type: "item",
		url: "/apps/settings",
		exact: true,
		auth: ['Admin'],
	},
	{
		id: "reports",
		title: "Reports",
		type: "item",
		url: "/apps/reports",
		exact: true,
		auth: ['Admin'],
	},
];

export default navigationConfig;
