import React from "react";

const ListPricesConfigs = {
    settings: {
        layout: {},
    },
    routes: [
        {
            path: "/apps/ListPrices",
            component: React.lazy(() => import("./ListPrices")),
            auth: ['Admin','Customer'],
        },
    ],
};

export default ListPricesConfigs;