import React from "react";

const ContactUsConfigs = {
    settings: {
        layout: {},
    },
    routes: [
        {
            path: "/apps/ContactUs",
            component: React.lazy(() => import("./ContactUs")),
            auth: ['Admin', 'Customer'],
        },
    ],
};

export default ContactUsConfigs;