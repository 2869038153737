import { setCartList } from "app/auth/store/userSlice";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";

const useCheckCart = () => {
	const dispatch = useDispatch()
	const [list, setList] = useState([]);

	useEffect(() => {
		window.addEventListener("storage", () => {
			let cart_arr = localStorage.getItem("cart_arr")
			if (cart_arr) {
				cart_arr = JSON.parse(cart_arr)
			} else {
				cart_arr = []
			}
			setList(cart_arr)
		})

	}, []);
	useEffect(() => {
		let cart_arrN = localStorage.getItem("cart_arr")
		if (cart_arrN) {
			cart_arrN = JSON.parse(cart_arrN)
		} else {
			cart_arrN = []
		}
		setList(cart_arrN)
	}, []);
	useEffect(() => {
		dispatch(setCartList(list))
	}, [list]);
	return null
}

export default useCheckCart