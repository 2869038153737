import React from "react";

const AccountUserConfigs = {
    settings: {
        layout: {},
    },
    routes: [
        {
            path: "/apps/profile",
            component: React.lazy(() => import("./AccountUser")),
            auth: ['Franchisee'],
        },
    ],
};

export default AccountUserConfigs;