const skyBlue = {
	50: '#e4fafd',
	100: '#bdf2fa',
	200: '#91e9f7',
	300: '#64e0f3',
	400: '#43daf1',
	500: '#22d3ee',
	600: '#1eceec',
	700: '#19c8e9',
	800: '#14c2e7',
	900: '#0cb7e2',
	A100: '#ffffff',
	A200: '#daf7ff',
	A400: '#a7ecff',
	A700: '#8de6ff',
	contrastDefaultColor: 'dark'
};

export default skyBlue;
