import React from "react";

const ReportsConfigs = {
    settings: {
        layout: {},
    },
    routes: [
        {
            path: "/apps/reports",
            component: React.lazy(() => import("./Reports")),
            auth: ['Admin'],
        },
        // {
        //     path: "/apps/user/reports",
        //     component: React.lazy(() => import("app/main/apps/Reports/profileReport/profileReport")),
        //     auth: ['Franchisee'],
        // },
    ],
};

export default ReportsConfigs;