import useCheckCart from '@fuse/hooks/useCheckCart';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FuseNavItem from '../FuseNavItem';

const useStyles = makeStyles(theme => ({
	navigation: {
		'& .fuse-list-item': {
			'&:hover': {
				backgroundColor: theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0,0,0,.04)'
			},
			'&:focus:not(.active)': {
				backgroundColor: theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.06)' : 'rgba(0,0,0,.05)'
			}
		},
		'&.active-square-list': {
			'& .fuse-list-item, & .active.fuse-list-item': {
				width: '100%',
				borderRadius: '0'
			}
		},
		'&.dense': {
			'& .fuse-list-item': {
				paddingTop: 0,
				paddingBottom: 0,
				height: 32
			}
		}
	}
}));

function FuseNavVerticalLayout1(props) {
	const check = useCheckCart()
	const cart = useSelector(({ auth }) => auth)
	let list = []
	if (cart && cart?.user && cart?.user?.cart) {
		list = cart?.user?.cart
	} else {
		list = []
	}


	const classes = useStyles(props);
	const { navigation, layout, active, dense, className, onItemClick } = props;
	const dispatch = useDispatch();

	function handleItemClick(item) {
		onItemClick && onItemClick(item);
	}
	const redirectURL = () => {
        let redirectUrl = localStorage.getItem("redirectUrl") || "/apps/dropAndUpload/new"
        return redirectUrl
    }

	return (
		<List
			className={clsx(
				"navigation whitespace-nowrap px-12",
				classes.navigation,
				`active-${active}-list`,
				dense && "dense",
				className
			)}
			style={{ paddingTop: "35px" }}
		>
			{navigation.map((_item) => {
				if (_item.id == "document-cart-list" && list.length == 0) return null
				if(_item.id == "document-add-Drop") {
					_item.url = redirectURL()
				}
				return (
					<FuseNavItem
						key={_item.id}
						type={`vertical-${_item.type}`}
						item={_item}
						nestedLevel={0}
						onItemClick={handleItemClick}
					/>
				);
			})}
		</List>
	);
}

export default FuseNavVerticalLayout1;
