import React from "react";

const FaqConfigs = {
    settings: {
        layout: {},
    },
    routes: [
        {
            path: "/apps/FAQ",
            component: React.lazy(() => import("./Faq")),
            auth: ['Admin'],
        },
    ],
};

export default FaqConfigs;